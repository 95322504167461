import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "@/core/services/store";
import ApiService from "@/core/services/api.service";
import MultiFiltersPlugin from "./plugins/MultiFilters";
import MockService from "@/core/mock/mock.service";
import { VERIFY_AUTH } from "@/core/services/store/auth.module";
import { RESET_LAYOUT_CONFIG } from "@/core/services/store/config.module";
import datePicker from "vue-bootstrap-datetimepicker";
import "vue-search-select/dist/VueSearchSelect.css";
import "pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css";
import axios from "axios";
import VueExcelXlsx from "vue-excel-xlsx";

import * as VueGoogleMaps from "vue2-google-maps";
Vue.config.productionTip = false;
// Global 3rd party plugins
import "popper.js";
import "tooltip.js";
import PerfectScrollbar from "perfect-scrollbar";
window.PerfectScrollbar = PerfectScrollbar;
import ClipboardJS from "clipboard";
window.ClipboardJS = ClipboardJS;

// Vue 3rd party plugins
import i18n from "@/core/plugins/vue-i18n";
import vuetify from "@/core/plugins/vuetify";
import "@/core/plugins/portal-vue";
import "@/core/plugins/bootstrap-vue";
import "@/core/plugins/perfect-scrollbar";
import "@/core/plugins/highlight-js";
import "@/core/plugins/inline-svg";
import "@/core/plugins/apexcharts";
import "@/core/plugins/treeselect";
import "@/core/plugins/metronic";
import "@mdi/font/css/materialdesignicons.css";
import "@/core/plugins/formvalidation";
import { getToken } from "@/core/services/Cookie";

// API service init
ApiService.init();

// Remove this to disable mock API
MockService.init();

// router.beforeEach((to, from, next) => {

//   // Ensure we checked auth before each page load.
//   Promise.all([store.dispatch(VERIFY_AUTH)]).then(next);

//   // reset config to initial state
//   //  store.dispatch(RESET_LAYOUT_CONFIG);

//   // Scroll page to top on every route change
//   setTimeout(() => {
//     window.scrollTo(0, 0);
//   }, 100);

//   if(!store.getters.GetIsUserAuthenticated){
//    if(to.name == "CreatePassword"){
//      next();
//    }
//   else if(to.name == "termandcondition"){
//      next();
//    }
//  else  if(to.name == "privacyPolicy"){
//      next();
//    }
//    else{
//     next('/login');
//    }
//   }
//   else{
//     if(to.path === '/login'){
//       next();
//     }
//     else{
//       next();
//     }
//   }

// });

// router.beforeEach((to, from, next) => {
//   // Ensure we checked auth before each page load.
//   Promise.all([store.dispatch(VERIFY_AUTH)]).then(next);

//   // reset config to initial state
//   store.dispatch(RESET_LAYOUT_CONFIG);

//   // Scroll page to top on every route change
//   setTimeout(() => {
//     window.scrollTo(0, 0);
//   }, 100);

// });

const preLoad = async () => {
  var token = JSON.parse(getToken());

  if (token != null && token != undefined) {
    await store.dispatch("AuthenticateUser", token).then((response) => {
      
      if (!response.status) {
        router.push({ name: "login" });
      }
    });
  }

  Vue.use(VueExcelXlsx);
  Vue.use(datePicker);
  Vue.use(
    VueGoogleMaps,
    {
      load: {
        key: "AIzaSyANTvw9RjoMgJGwvgGCXWhhtdSpgl0r8wM",
        libraries: "places",
      },
    },
    MultiFiltersPlugin
  );

  new Vue({
    router,
    store,
    i18n,
    vuetify,
    render: (h) => h(App),
  }).$mount("#app");
};
preLoad();
