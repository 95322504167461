import { fetchApi } from "../../request";
export default {
  state: {
    timeTracker: [],
    serviceList: [],
    caregiverList: [],
    editTimeTaker: null,
    totalTimeTracker: 0,
    getWorkedHour: [],
    getSubCodesById: [],
  },
  getters: {
    editTimeTaker(state) {
      return state.editTimeTaker;
    },
    timeTracker(state) {
      if (state.timeTracker.length > 0) {
        return state.timeTracker;
      }
    },
    serviceList(state) {
      if (state.serviceList.length > 0) {
        return state.serviceList;
      }
    },
    caregiverList(state) {
      if (state.caregiverList.length > 0) {
        return state.caregiverList;
      }
    },
    totalTimeTracker(state) {
      return state.totalTimeTracker;
    },
    getWorkedHour(state) {
      return state.getWorkedHour;
    },
  },
  actions: {
    editTimeTaker({ commit }, payload) {
      commit("editTimeTaker", payload);
    },
    async getTimeTracker({ commit }, payload) {
      return await fetchApi("api/TimeTracker", payload)
        .then((response) => {
          commit("setTimeTracker", response.data);
          return response.data;
        })
        .catch((ex) => {
          
        });
    },


    async updateBulkEntries({ commit }, payload) {
      return await fetchApi(
        `api/TimeTracker/updateBulkEntries`,
        payload,
        "Post",
        {
          "Content-Type": "application/json",
        }
      )
        .then((response) => {
          return response;
        })
        .catch((ex) => {});
    },
    async getCaregiverById({ commit }, payload) {
      return await fetchApi(
        "api/AssignCaregiver/GetCaregiversByIds",
        payload
      ).then((response) => {
        commit("setCaregiverList", response.data);
        return response.data;
      });
    },
    async getSubCodesById({ commit }, payload) {
      return await fetchApi("api/AssignCaregiver/GetSubCodesByIds", payload)
        .then((response) => {
          commit("setServiceList", response.data);
          return response.data;
        })
        .catch((ex) => {
          
        });
    },
    async getSubCodes({ commit }, payload) {
      return await fetchApi("api/SubCode/GetSubCode")
        .then((response) => {
          commit("setServiceList", response.data);
          return response.data;
        })
        .catch((ex) => {
          
        });
    },
    async getWorkedHours({ commit }, payload) {
      return await fetchApi("api/TimeTracker/getWorkedHours", payload)
        .then((response) => { 
          commit("getWorkedHour", response.data);
          return response.date;
        })
        .catch((ex) => {
          
        });
    },
    async PostManualEntry({ commit }, payload) {
      return await fetchApi(
        "api/TimeTracker/PostManualEntry",
        payload,
        "Post",
        { "Content-Type": "application/json" }
      )
        .then((response) => {
            return response;
        })
        .catch((ex) => {
          
        });
    },
    saveTimeTracker({ commit }, payload) {
      return fetchApi("api/TimeTracker", payload, "Post", {
        "Content-Type": "application/json",
      })
        .then((response) => {
          return response;
        })
        .catch((ex) => {
          
        });
    },
    updateTimeTracker({ commit }, payload) {
      return fetchApi("api/TimeTracker", payload, "PUT", {
        "Content-Type": "application/json",
      })
        .then((response) => {
             return response;
        })
        .catch((ex) => {
          
        });
    },
    removeTimeTracker({ commit }, payload) {
      return fetchApi(`api/TimeTracker/${payload}/`, null, "DELETE")
        .then((response) => {
          return response;
        })
        .catch((ex) => {
          
        });
    },
  },
  mutations: {
    editTimeTaker(state, payload) {
      state.editTimeTaker = payload;
    },
    setTimeTracker(state, payload) {
      state.timeTracker = payload.output;
      state.totalTimeTracker = payload.totalRow;
    },
    setServiceList(state, payload) {
      state.serviceList = payload;
    },
    setCaregiverList(state, payload) {
      state.caregiverList = payload;
    },
    removeVendors(state, payload) {
      state.timeTracker = state.timeTracker.filter(function (value) {
        return value.id != payload;
      });
    },
    getWorkedHour(state, payload) {
      state.getWorkedHour = payload;
    },
  },
};
