import { fetchApi } from "../../request";
export default {
    state: {
        payers: [],
        totalRow: 0,
        caseWorkerList:null
    },
    getters: {
        payers(state) {
            if (state.payers.length > 0) {
                return state.payers;
            }
        },
        totalRow(state) {
            return state.totalRow;
        },
        caseWorkerList(state) {
            
            return state.caseWorkerList;
        },
    },
    actions: {
        async getPayers({ commit }, payload) {
            return await fetchApi("api/Payers", payload)
                .then((response) => {
                    commit("setPayers", response.data);
                    return response;
                })
                .catch((ex) => {
                    
                });
        },
        savePayers({ commit }, payload) {
            
            return fetchApi('api/Payers', { 
                type: payload.type,
                companyName: payload.companyName,
                phoneNumber: payload.phoneNumber,
                location: payload.location,
                payersCaseWorkers:payload.payersCaseWorkers
            }, 'Post', { "Content-Type": "application/json" })
                .then((response) => {
                    commit("savePayers", {
                        id: response.data, type: payload.type,
                        companyName: payload.companyName,
                        phoneNumber: payload.phoneNumber,
                        location: payload.location,
                    }); return response;
                })
                .catch((ex) => {
                    
                });
        },
        editPayers({ commit }, payload) {
            return fetchApi('api/Payers', {
                id: payload.id,
                type: payload.type,
                companyName: payload.companyName,
                phoneNumber: payload.phoneNumber,
                location: payload.location,
            }, 'Put', { "Content-Type": "application/json" })
                .then((response) => {
                    ;
                    commit("updatePayers", payload);
                    return response
                })
                .catch((ex) => {
                    
                });
        },
        removePayers({ commit }, payload) {
            
            return fetchApi(`api/Payers/${payload.id}/`, null, 'DELETE')
                .then((response) => {

                    commit("removePayers", payload.id); return response
                })
                .catch((ex) => {
                    ;
                    
                });
        },
        async getCaseWorkerListbyPayerId({ commit }, payload) {
            return await fetchApi("api/CaseWorker/GetCaseWorkersByPayerId", payload)
                .then((response) => {
                    commit("setCaseWorkerList", response.data);
                    return response;
                })
                .catch((ex) => {

                    
                });
        },
        async getCaseWorkerList({ commit }) { 
            return await fetchApi("api/CaseWorker/GetCaseWorkers")
              .then((response) => { 
                commit("setCaseWorkerList", response.data);
                return response;
              })
              .catch((ex) => {
                
                
              });
          },
    },
    mutations: {
        setPayers(state, payload) {
            state.payers = payload.output;
            state.totalRow = payload.totalRow;
        },
        setCaseWorkerList(state, payload) {
            state.caseWorkerList = payload; 
        },
        removePayers(state, payload) {
            state.payers=state.payers.filter(function (value) {
                return value.id != payload;
            });
        },
        updatePayers(state, payload) {
            
            var index = state.payers.findIndex(x => x.id == payload.id);
            state.payers[index].type = payload.type
            state.payers[index].companyName = payload.companyName
            state.payers[index].phoneNumber = payload.phoneNumber
            state.payers[index].location = payload.location
        },
        savePayers(state, payload) {
            
            state.payers.unshift(payload);
        },
    },
};
