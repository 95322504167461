import { fetchApi } from "../../request";
export default {
  state: {
    EVVForm: [],
    totalRow: 0,
  },
  getters: {
    EVVForm(state) {
      if (state.EVVForm.length > 0) {
        return state.EVVForm;
      }
    },
    totalRow(state) {
      return state.totalRow;
    },
  },
  actions: {
    async getEVVForm({ commit }, payload) {
      return await fetchApi("api/EVVForm/GetEVVForms", payload)
        .then((response) => {
          commit("setEVVForm", response.data);
          return response;
        })
        .catch((ex) => {});
    },
  },
  mutations: {
    setEVVForm(state, payload) {
      state.EVVForm = payload.output;
      state.totalRow = payload.totalRow;
    },
  },
};
