import Vue from "vue";
import Vuex from "vuex";
import auth from "./auth.module";
import htmlClass from "./htmlclass.module";
import config from "./config.module";
import breadcrumbs from "./breadcrumbs.module";
import profile from "./profile.module";
import client from "./client/";
import caseWorkers from "./caseWorkers";
import payers from "./payers";
import vendors from "./vendor";
import subcode from "./subcode";
import employee from "./employee";
import timeTracker from "./timeTracker";
import payroll from "./payroll";
import report from "./report";
import users from "./User";
import evvform from "./evvform"
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    users,
    auth,
    htmlClass,
    config,
    breadcrumbs,
    profile,
    client,
    caseWorkers,
    payers,
    vendors,
    subcode,
    employee,
    timeTracker,
    payroll,
    report,
    evvform
  },
});
