import { fetchApi } from "../../request";
export default {
  state: {
    caseWorkers: [],
    totalRow: 0
  },
  getters: {
    getCaseWorkers(state) {
      return state.caseWorkers;
    },
    totalcaseWorker(state) { 
      return state.totalRow;
    },
  },
  actions: {
    async getCaseWorkers({ commit }, payload) {

      return fetchApi("api/CaseWorker", payload)
        .then((response) => {
          commit("setCaseWorkers", response.data);
          return response.data
        })
        .catch((ex) => {

          
        });
    },
    saveCaseWorkers({ commit }, payload) {

      return fetchApi('api/CaseWorker', payload, 'Post', { "Content-Type": "application/json" })
        .then((response) => {

          commit("saveCaseWorkers", {
            id: response.data,
            firstName: payload.firstName,
            lastName: payload.lastName,
          }); return response
        })
        .catch((ex) => {

          
        });
    },
    editCaseWorkers({ commit }, payload) {

      return fetchApi('api/CaseWorker',  payload, 'Put', { "Content-Type": "application/json" })
        .then((response) => {
          commit("updateCaseWorkers", payload);
          return response
        })
        .catch((ex) => {

          
        });
    },
    removeCaseWorkers({ commit }, payload) {

      return fetchApi(`api/CaseWorker/${payload.id}/`, null, 'DELETE')
        .then((response) => {

          commit("removeCaseWorkers", payload.id); return response
        })
        .catch((ex) => {

          
        });
    },
  },
  mutations: {
    setCaseWorkers(state, payload) {
      state.caseWorkers = payload.output;
      state.totalRow = payload.totalRow;
    },
    removeCaseWorkers(state, payload) {

      state.caseWorkers = state.caseWorkers.filter(function (value) {
        return value.id != payload;
      });
    },
    updateCaseWorkers(state, payload) {

      var index = state.caseWorkers.findIndex(x => x.id == payload.id);
      state.caseWorkers[index].firstName = payload.firstName;
      state.caseWorkers[index].lastName = payload.lastName;
      state.caseWorkers[index].idNumber = payload.idNumber;
      state.caseWorkers[index].status = payload.status;
    },
    saveCaseWorkers(state, payload) {
      state.caseWorkers.unshift(payload);
    },
  },
};
