import { fetchApi } from "../../request";
import { getToken, saveToken, destroyToken } from "../../Cookie";
export default {
  state: {
    users: [],
    user: null,
    AuthenticatedUser: null,
    isUserAuthenticated: false,
  },
  getters: {
    GetUsers(state) {
      return state.users;
    },
    GetUser(state) {
      return state.user;
    },
    GetIsUserAuthenticated(state) { 
      if (getToken() != null) {
        state.isUserAuthenticated = true;
      }
      return state.isUserAuthenticated;
    },
    GetAuthenticatedUser(state) {
      return state.AuthenticatedUser;
    },
  },
  actions: {
    async getUsers({ commit }, payload) {
      return await fetchApi("api/Users", payload)
        .then((response) => {
          commit("setUsers", response.data);
          return response.data;
        })
        .catch((ex) => {});
    },
    async getUser({ commit }, payload) {
      return await fetchApi("api/Users/" + payload)
        .then((response) => {
          commit("setUser", response.data);
          return response.data;
        })
        .catch((ex) => {});
    },
    async removeuser({ commit }, payload) {
      return await fetchApi("api/Users/" + payload, null, "Delete", {
        "Content-Type": "application/json",
      })
        .then((response) => {
          return response;
        })
        .catch((ex) => {});
    },
    async saveusers({ commit }, payload) {
      return await fetchApi("api/Users", payload, "Post", {
        "Content-Type": "application/json",
      })
        .then((response) => {
          return response;
        })
        .catch((ex) => {});
    },
    async resendCredentials({ commit }, payload) {
      return await fetchApi("api/Users/ResendCredentials", payload, "Post", {
        "Content-Type": "application/json",
      })
        .then((response) => {
          return response;
        })
        .catch((ex) => {});
    },
    async savePassword({ commit }, payload) {
      return await fetchApi("api/Users", payload, "put", {
        "Content-Type": "application/json",
      })
        .then((response) => {
          return response;
        })
        .catch((ex) => {});
    },
    async ResetPassword({ commit }, payload) {
      return await fetchApi(
        `api/Users/ResetPassword/${payload}`,
        null,
        "post",
        { "Content-Type": "application/json" }
      )
        .then((response) => {
          return response;
        })
        .catch((ex) => {});
    },
    async AuthenticateUser({ commit }, payload) {
      return await fetchApi("api/Users/Login", payload, "Post", {
        "Content-Type": "application/json",
      })
        .then((response) => {
          if (response.status && response.data ) {
              commit("setAuthenticatedUser", response.data);
          } else if (response.message === "Token Expired") {
              commit("LogoutUser");
            }
           
          return response;
        })
        .catch((ex) => {});
    },
    async VerifyOtp({ commit }, payload) {
      return await fetchApi("api/Users/VerifyOTP", payload)
        .then((response) => {
          if (response.status && response.data ) {
            commit("setAuthenticatedUser", response.data);
          } else if (response.message === "Token Expired") {
              commit("LogoutUser");
            }
           
          return response;
        })
        .catch((ex) => {});
    },
    async LogoutUser({ commit }) {
      commit("LogoutUser");
    },
  },
  mutations: {
    setUsers(state, payload) {
      state.users = payload;
    },
    setUser(state, payload) {
      state.user = payload;
    },
    setAuthenticatedUser(state, payload) {
      state.isUserAuthenticated = true;
      state.AuthenticatedUser = payload;
      var token = {
        token: payload.token,
        email: payload.email,
      };
      saveToken(JSON.stringify(token));
    },
    LogoutUser(state) {
      destroyToken();
      state.isUserAuthenticated = false;
      state.AuthenticatedUser = null;
    },
  },
};
