import Vue from "vue";
import Router from "vue-router";

import store from "@/core/services/store";

Vue.use(Router);

const router = new Router({
  mode: "history",
  routes: [
    {
      path: "/dashboard",
      redirect: "/dashboard",
      component: () => import("./view/layout/clientLayout"),
      children: [
        {
          path: "/dashboard",
          name: "dashboard",
          component: () => import("@/view/pages/dashboard1.vue"),
        },
        {
          path: "/client",
          name: "clients",
          component: () => import("./view/client"),
        },
        {
          path: "/createClient",
          name: "createClient",
          component: () => import("./view/client/create.vue"),
        },
        {
          path: "/vendorCode",
          name: "vendorCde",
          component: () => import("./view/vendor"),
        },
        {
          path: "/bulkAuthorization",
          name: "bulkAuthorization",
          component: () => import("./view/bulkAuthorization"),
        },
        {
          path: "/subCode",
          name: "subCode",
          component: () => import("./view/subCode"),
        },
        {
          path: "/payer",
          name: "payers",
          component: () => import("./view/payer"),
        },
        {
          path: "/roles",
          name: "roles",
          component: () => import("./view/role"),
        },
        {
          path: "/user",
          name: "users",
          component: () => import("./view/user"),
        },
        {
          path: "/import",
          name: "import",
          component: () => import("./view/Import"),
        },
        {
          path: "/ProspectiveClient",
          name: "ProspectiveClientList",
          component: () => import("./view/prospectiveClient"),
        },
        {
          path: "/CaseWorker",
          name: "CaseWorker",
          component: () => import("./view/caseWorkers"),
        },
        {
          path: "/evvform",
          name: "evvform",
          component: () => import("./view/evvform"),
        },
        {
          path: "/clockin",
          name: "clockin",
          component: () => import("./view/clockin"),
        },
        {
          path: "/evvformRe",
          name: "evvformRe",
          component: () => import("./view/evvformRe"),
        },
      ],
    },

    {
      path: "/timeKeeping",
      name: "timeKeepings",
      component: () => import("./view/layout/clientLayout"),
      children: [
        {
          path: "/timeKeeping",
          name: "timeKeeping",
          component: () => import("./view/timeKeeping"),
        },
        {
          path: "/timeEntry",
          name: "timeEntry",
          component: () => import("./view/timeEntry"),
        },
      ],
    },
    {
      path: "/payRoll",
      name: "payRoll",
      component: () => import("./view/layout/clientLayout"),
      children: [
        {
          path: "/payRoll",
          name: "payRolls",
          component: () => import("./view/payRoll"),
        },
        {
          path: "/draftpayroll",
          name: "draftpayrolls",
          component: () => import("./view/payRoll/draftedPayRoll.vue"),
        },
        {
          path: "/currentpayroll",
          name: "currentpayroll",
          component: () => import("./view/payRoll/currentPayroll.vue"),
        },
      ],
    },
    {
      path: "/reports",
      name: "reports",
      component: () => import("./view/layout/clientLayout"),
      children: [
        {
          path: "/clientreport",
          name: "clientreport",
          component: () => import("./view/reports/client.vue"),
        },
        {
          path: "/detailreport",
          name: "detailreport",
          component: () => import("./view/reports/detail.vue"),
        },
        {
          path: "/clientbillingsummary",
          name: "clientbillingsummary",
          component: () => import("./view/reports/clientbillingsummary.vue"),
        },
        {
          path: "/clientbillingsummary2",
          name: "clientbillingsummary2",
          component: () => import("./view/reports/clientBillingSummary2.vue"),
        },
        {
          path: "/censussummary",
          name: "censussummary",
          component: () => import("./view/reports/censusSummary.vue"),
        },
        {
          path: "/clientcontactsummary",
          name: "clientcontactsummary",
          component: () => import("./view/reports/clientcontactsummary.vue"),
        },
        {
          path: "/employeecontactsummary",
          name: "employeecontactsummary",
          component: () => import("./view/reports/employeecontactsummary.vue"),
        },
        {
          path: "/refferalsummary",
          name: "refferalsummary",
          component: () => import("./view/reports/refferalReport.vue"),
        },
        {
          path: "/overtimeReport",
          name: "overtimeReport",
          component: () => import("./view/reports/overtimeReport.vue"),
        },
        {
          path: "/inactivityReport",
          name: "inactivityReport",
          component: () => import("./view/reports/inactivityReport.vue"),
        },
        {
          path: "/JFSInvoice",
          name: "JFSInvoice",
          component: () => import("./view/reports/JFSReport.vue"),
        },
        {
          path: "/ExpirationSummary",
          name: "ExpirationSummary",
          component: () => import("./view/reports/licenceexpiration.vue"),
        },
        {
          path: "/RegionalCenterInvoicing",
          name: "RegionalCenterInvoicing",
          component: () => import("./view/reports/regionalcenterInvoicing.vue"),
        },
      ],
    },
    {
      path: "/messaging",
      name: "messaging",
      component: () => import("./view/layout/clientLayout"),
      children: [
        {
          path: "/Email",
          name: "Email",
          component: () => import("./view/Email/Emails.vue"),
        },
        {
          path: "/EmailTemplate",
          name: "EmailTemplate",
          component: () => import("./view/Email/EmailTemplate.vue"),
        },
        // {
        //   path: "/incoming",
        //   name: "incoming",
        //   component: () =>
        //     import(

        //       "./view/messaging/incoming.vue"
        //     ),
        // },
        // {
        //   path: "/outgoing",
        //   name: "outgoing",
        //   component: () =>
        //     import(

        //       "./view/messaging/outgoing.vue"
        //     ),
        // },
      ],
    },
    {
      path: "/caregiver",
      component: () => import("./view/layout/clientLayout"),
      children: [
        {
          path: "/",
          name: "Caregiver",
          component: () => import("./view/caregiver"),
        },
        {
          path: "/OtherStaff",
          name: "OtherStaff",
          component: () => import("./view/otherStaff"),
        },
        {
          path: "/CareCoordinator",
          name: "Care Coordinator",
          component: () => import("./view/careCoordinator"),
        },
        {
          path: "/CareGiverApplicant",
          name: "CareGiverApplicant",
          component: () => import("./view/caregiverApplicant"),
        },
      ],
    },
    {
      path: "/custom-error",
      name: "error",
      component: () => import("@/view/pages/error/Error.vue"),
      children: [
        {
          path: "error-1",
          name: "error-1",
          component: () => import("@/view/pages/error/Error-1.vue"),
        },
        {
          path: "error-2",
          name: "error-2",
          component: () => import("@/view/pages/error/Error-2.vue"),
        },
        {
          path: "error-3",
          name: "error-3",
          component: () => import("@/view/pages/error/Error-3.vue"),
        },
        {
          path: "error-4",
          name: "error-4",
          component: () => import("@/view/pages/error/Error-4.vue"),
        },
        {
          path: "error-5",
          name: "error-5",
          component: () => import("@/view/pages/error/Error-5.vue"),
        },
        {
          path: "error-6",
          name: "error-6",
          component: () => import("@/view/pages/error/Error-6.vue"),
        },
      ],
    },
    {
      path: "/",
      redirect: "/login",
      component: () => import("./view/layout/accountLayOut"),
      children: [
        {
          name: "login",
          path: "/login",
          public: true,
          component: () => import("./view/account/login.vue"),
        },

        {
          path: "/signUp",
          name: "signUp",
          component: () => import("./view/account/signUp.vue"),
        },
        {
          path: "/forgotPassword",
          name: "forgotPassword",
          component: () => import("./view/account/forgotPassword.vue"),
        },
      ],
    },

    {
      path: "/",
      component: () => import("./view/layout/accountLayOut"),
      children: [
        {
          path: "/CreatePassword/:id",
          name: "CreatePassword",
          component: () => import("./view/account/CreatePassword"),
        },
        {
          path: "/ResetPassword/:id",
          name: "ResetPassword",
          component: () => import("./view/account/ResetPassword"),
        },
      ],
    },
    {
      path: "/termandcondition",
      name: "termandcondition",
      component: () => import("./view/layout/publicLayout"),
      children: [
        {
          path: "/termandcondition",
          name: "termandcondition",
          component: () => import("./view/account/termandcondition"),
        },
      ],
    },
    {
      path: "/privacyPolicy",
      name: "privacyPolicy",
      component: () => import("./view/layout/publicLayout"),
      children: [
        {
          path: "/privacyPolicy",
          name: "privacyPolicy",
          component: () => import("./view/account/privacyPolicy"),
        },
      ],
    },
    {
      path: "/verifyOTP",
      name: "verifyOTP",
      component: () => import("./view/layout/publicLayout"),
      children: [
        {
          path: "/verifyOTP",
          name: "verifyOTP",
          component: () => import("./view/account/verifyOTP"),
        },
      ],
    },
    {
      path: "*",
      redirect: "/404",
    },
    {
      path: "/404",
      name: "404",
      component: () => import("@/view/pages/error/Error-1.vue"),
    },
  ],
});

router.beforeEach((to, from, next) => {
  if (!store.getters.GetIsUserAuthenticated) {
    if (to.name == "CreatePassword") {
      next();
    } else if (to.name == "ResetPassword") {
      next();
    } else if (to.name == "termandcondition") {
      next();
    } else if (to.path == "/ForgotPassword") {
      next();
    } else if (to.name == "privacyPolicy") {
      next();
    } else if (to.name == "verifyOTP") {
      next();
    } else if (to.name == "login") {
      next();
    } else {
      next("/login");
    }
  } else {
    if (
      to.name == "users" ||
      to.name == "Email" ||
      to.name == "payers" ||
      to.name == "vendorCde" ||
      to.name == "subCode" ||
      to.name == "import"
    ) {
      if (store.getters.GetAuthenticatedUser.userType == "Super Admin") {
        next();
      } else {
        next("/dashboard");
      }
    } else if (
      to.name == "timeEntry" ||
      to.name == "payRolls" ||
      to.name == "draftpayrolls"
    ) {
      if (
        store.getters.GetAuthenticatedUser.userType == "Super Admin" ||
        store.getters.GetAuthenticatedUser.userType == "JFS" ||
        store.getters.GetAuthenticatedUser.userType == "Admin Level 2"
      ) {
        next();
      } else {
        next("/dashboard");
      }
    } else {
      next();
    }
  }
});

export default router;
