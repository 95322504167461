
import { fetchApi, fetchNormal } from "../../request";
import ApiService from "../../api.service";
export default {
    state: {
        Employees: [],
        OtherStaff: [],
        totalRow: 0,
        totalOtherStaffRow: 0,
        editEmployee: null,
        editOtherStaff: null,
    },
    getters: {
        Employees(state) {
            return state.Employees;
        },
        totalEmployeesRow(state) {
            return state.totalRow;
        },
        OtherStaff(state) {
            return state.OtherStaff;
        },
        totalOtherStaffRow(state) {
            return state.totalOtherStaffRow;
        },
        editEmployee(state) {
            return state.editEmployee;
        },
        editOtherStaff(state) {
            return state.editOtherStaff;
        },
    },
    actions: {
        signUpEmployee({ commit }, payload) {
            return fetchApi('api/Employee/SignUpcaregiver', payload, 'Post', { "Content-Type": "application/json" })
        },
        saveEmployee({ commit }, payload) {
            return fetchApi('api/Employee', payload, 'Post', { "Content-Type": "application/json" })
                .then((response) => {
                    if (response.message == "Success") {
                        commit("saveEmployee", payload);
                    }
                    return response;
                })
                .catch((ex) => {
                    
                });
        },
        ForgotPassword({ commit }, payload) {
            return fetchApi(`api/Employee/ForgetPassword?email=${payload}`, null, 'Post', { "Content-Type": "application/json" })
                .then((response) => {
                    return response;
                })
                .catch((ex) => {
                    
                });
        },
        ResendCredentials({ commit }, payload) {
            return fetchApi(`api/Employee/ResendCredentials?email=${payload}`, null, 'Post', { "Content-Type": "application/json" })
                .then((response) => {
                    return response;
                })
                .catch((ex) => {
                    
                });
        },
        saveOtherStaff({ commit }, payload) {
            return fetchApi('api/OtherStaff', payload, 'Post', { "Content-Type": "application/json" })
                .then((response) => {
                    if (response.message == "Success") {
                        commit("saveOtherStaf", payload);
                    }
                    return response;
                })
                .catch((ex) => {
                    
                });
        },

        updateOtherStaff({ commit }, payload) {
            return fetchApi('api/OtherStaff', payload, 'Put', { "Content-Type": "application/json" })
                .then((response) => {
                    return response;
                })
                .catch((ex) => {
                    
                });
        },
        updateEmployee({ commit }, payload) {
            return fetchApi('api/Employee', payload, 'Put', { "Content-Type": "application/json" })
                .then((response) => {
                    return response;
                })
                .catch((ex) => {
                    
                });
        },
        saveEmployeePayRate({ commit }, payload) {
            return fetchApi('api/EmployeePayRate', payload, 'Post', { "Content-Type": "application/json" })
                .then((response) => {

                    return response;
                })
                .catch((ex) => {
                    
                });
        },
        updateEmployeePayRate({ commit }, payload) {
            return fetchApi('api/EmployeePayRate', payload, 'Put', { "Content-Type": "application/json" })
                .then((response) => {

                    return response;
                })
                .catch((ex) => {
                    
                });
        },
        removeEmployeePayRate({ commit }, payload) {
            return fetchApi(`api/EmployeePayRate/${payload.id}`, null, 'DELETE')
                .then((response) => {

                    return response;
                })
                .catch((ex) => {
                    
                });
        },
        removeEmployeeCompliance({ commit }, payload) {
            return fetchApi(`api/EmployeeCompliance/${payload.id}`, null, 'DELETE')
                .then((response) => {

                    return response;
                })
                .catch((ex) => {
                    
                });
        },
        removeDocument({ commit }, payload) {
            return fetchApi(`api/EmployeeDocument/${payload.id}`, null, 'DELETE')
                .then((response) => {
                    return response;
                })
                .catch((ex) => {
                    
                });
        },
        saveDocument({ commit }, payload) {
            var formData = new FormData();
            formData.append('body', payload.body)
            formData.append('file', payload.file)
            return fetchNormal('api/EmployeeDocument', formData, 'Post')
                .then((response) => {

                    return response;
                })
                .catch((ex) => {
                    
                });
        },
        updateDocument({ commit }, payload) {
            var formData = new FormData();
            formData.append('body', payload.body)
            formData.append('file', payload.file)
            return fetchNormal('api/EmployeeDocument', formData, 'Put')
                .then((response) => {
                    return response;
                })
                .catch((ex) => {
                    
                });
        },
        saveCompliance({ commit }, payload) {
            var formData = new FormData();
            formData.append('body', payload.body)
            formData.append('file', payload.file)
            return fetchNormal('api/EmployeeCompliance', formData, 'Post')
                .then((response) => {
                    return response;
                })
                .catch((ex) => {
                    
                });
        },
        updateCompliance({ commit }, payload) {
            var formData = new FormData();
            formData.append('body', payload.body)
            formData.append('file', payload.file)
            return fetchNormal('api/EmployeeCompliance', formData, 'Put')
                .then((response) => {
                    return response;
                })
                .catch((ex) => {
                    
                });
        },

        saveHireDate({ commit }, payload) {
            return fetchApi(`api/Employee/PostHireDate?id=${payload.id}` + `&hireDate=${payload.hireDate}`, null, 'Post')
                .then((response) => {
                    return response;
                })
                .catch((ex) => {
                    
                });
        },
        async saveBulkData({ commit }, payload) { 
            return await fetchApi(`api/Employee/BulkLoad`, payload, 'Post')
                .then((response) => {
                    return response;
                })
                .catch((ex) => {
                    
                });
        },
        async saveClientBulkData({ commit }, payload) { 
            return await fetchApi(`api/Client/ImportClients`, payload, 'Post')
                .then((response) => {
                    return response;
                })
                .catch((ex) => {
                    
                });
        },
        getEmployeeHireDate({ commit }, payload) {
            return fetchApi(`api/Employee/GetHireDateById?id=${payload}`, null)
                .then((response) => {
                    return response.data;
                })
                .catch((ex) => {
                    
                });
        },

        async getEmployee({ commit }, payload) {
            return await fetchApi('api/Employee', payload)
                .then((response) => {
                    if (response.message == "Success") {
                        commit("setEmployees", response.data);
                        return response;
                    }
                })
                .catch((ex) => {
                    
                });
        },
        async getStaff({ commit }, payload) {
            return await fetchApi('api/OtherStaff', payload)
                .then((response) => {
                    if (response.message == "Success") {
                        commit("setOtherStaff", response.data);
                        return response;
                    }
                })
                .catch((ex) => {
                    
                });
        },
        async getEmployeePayRate({ commit }, payload) {
            return await fetchApi('api/EmployeePayRate', payload)
                .then((response) => {
                    return response;
                })
                .catch((ex) => {
                    
                });
        },
        async getEmployeeCompliance({ commit }, payload) {
            return await fetchApi('api/EmployeeCompliance', payload)
                .then((response) => {
                    return response;
                })
                .catch((ex) => {
                    
                });
        },
        async getEmployeeDocument({ commit }, payload) {
            return await fetchApi('api/EmployeeDocument', payload)
                .then((response) => {
                    return response;
                })
                .catch((ex) => {
                    
                });
        },
        async getEmployeePhone({ commit }, payload) {
            return await fetchApi('api/Employee/GetPhones', payload)
                .then((response) => {
                    return response;
                })
                .catch((ex) => {
                    
                });
        },
        async getOtherStaffPhone({ commit }, payload) {
            return await fetchApi('api/OtherStaff/GetPhones', payload)
                .then((response) => {
                    return response;
                })
                .catch((ex) => {
                    
                });
        },
        editEmployee({ commit }, payload) {
            if (payload) {
                return fetchApi(`api/Employee/${payload}/`, null, 'GET')
                    .then((response) => {
                        commit("editEmployee", response.data);
                        return response.data
                    })
            } else {
                commit("editEmployee", null);
            }
        },
        async editOtherStaff({ commit }, payload) {
            if (payload) {
                return await fetchApi(`api/Employee/${payload}/`, null, 'GET')
                    .then((response) => {
                        commit("editEmployee", response.data);
                        return response.data
                    })
            } else {
                commit("editEmployee", null);
            }

        },

        async editStaff({ commit }, payload) {
            if (payload) {
                return await fetchApi(`api/OtherStaff/${payload}/`, null, 'GET')
                    .then((response) => {
                        commit("editEmployee", response.data);
                        return response.data
                    })
            } else {
                commit("editEmployee", null);
            }

        },
        removeStaff({ commit }, payload) {
            return fetchApi(`api/OtherStaff/${payload.id}/`, null, 'DELETE')
                .then((response) => {
                    return response
                })
                .catch((ex) => {
                    
                });
        },
        async RemoveEmployeePhone({ commit }, payload) {
            if (payload) {
                return await fetchApi(`api/Employee/DeletePhoneById?id=${payload.id}`, null, 'DELETE')
                    .then((response) => {
                        return response
                    })
            } else {
                commit("editEmployee", null);
            }

        },
        async RemoveOtherStaffPhone({ commit }, payload) {
            if (payload) {
                return await fetchApi(`api/OtherStaff/DeletePhoneById?id=${payload.id}`, null, 'DELETE')
                    .then((response) => {
                        return response
                    })
            } else {
                commit("editEmployee", null);
            }

        },
        emptyeditEmployee({ commit }, payload) {
            commit("editEmployee", payload);
        },
        emptyeditOtherStaff({ commit }, payload) {
            commit("editEmployee", payload);
        },
        savePhone({ commit }, payload) {
            return fetchApi('api/Employee/PostPhones', payload, 'Post', { "Content-Type": "application/json" })
                .then((response) => {
                    if (response.message == "Success") {
                        return response;
                    }
                })
                .catch((ex) => {
                    
                });
        },
        putEmail({ commit }, payload) {
            return fetchApi('api/Employee/PutEmail', payload, 'Put', { "Content-Type": "application/json" })
                .then((response) => {
                    return response;
                })
                .catch((ex) => {
                    
                });
        },
        putPhone({ commit }, payload) {
            return fetchApi('api/Employee/PutPhones', payload, 'Put', { "Content-Type": "application/json" })
                .then((response) => {
                    if (response.message == "Success") {
                        return response;
                    }
                })
                .catch((ex) => {
                    
                });
        },
        saveOtherStaffPhone({ commit }, payload) {
            return fetchApi('api/OtherStaff/PostPhones', payload, 'Post', { "Content-Type": "application/json" })
                .then((response) => {
                    if (response.message == "Success") {
                        return response;
                    }
                })
                .catch((ex) => {
                    
                });
        },
        putOtherStaffPhone({ commit }, payload) {
            return fetchApi('api/OtherStaff/PutPhones', payload, 'Put', { "Content-Type": "application/json" })
                .then((response) => {
                    if (response.message == "Success") {
                        return response;
                    }
                })
                .catch((ex) => {
                    
                });
        },
    },
    mutations: {
        saveEmployee(state, payload) {
            state.Employees.unshift(payload);
        },
        saveOtherStaf(state, payload) {
            state.OtherStaff.unshift(payload);
        },
        setEmployees(state, payload) {
            state.Employees = payload.output;
            state.totalRow = payload.totalRow;
        },
        setOtherStaff(state, payload) {
            state.OtherStaff = payload.output;
            state.totalOtherStaffRow = payload.totalRow;
        },
        editEmployee(state, payload) {
             state.editEmployee = payload;
        },
        editOtherStaff(state, payload) {
            state.editOtherStaff = payload;
        },
    },
};
