import fetchival from 'fetchival'; 
export const fetchApi = async (
  endPoint,
  payload = {},
  method = 'get',
  headers = {},
) => {
//   const token = localStorage.getItem('token');
//   if (token !== null) {
//     headers.Authorization = `JWT ${token}`;
//   }
  return fetchival(`${process.env.VUE_APP_API_URL}${endPoint}`, {
    headers,
  })[method.toLowerCase()](payload);
};

export const fetchNormal = async (
  endPoint,
  payload = {},
  method = 'get',
  headers = {},
) => {
  const token = localStorage.getItem('token');
  if (token !== null) {
    headers.Authorization = `JWT ${token}`;
  }
  return fetch(`${process.env.VUE_APP_API_URL}${endPoint}`, {
    method: method.toUpperCase(),
    body: payload,
    headers: headers,
  });
};
export const fetchNormalApi = async (
  endPoint,
  payload = {},
  method = 'get',
  headers = {},
) => {
  const token = localStorage.getItem('token');
  if (token !== null) {
    headers.Authorization = `JWT ${token}`;
  }
  return fetch(`${endPoint}`, {
    method: method.toUpperCase(),
    body: payload,
    headers: headers,
  });
};

// export const fetchDownload = async (
//   endPoint,
//   payload = {},
//   method = 'get',
//   headers = {},
// ) => {
//   const token = localStorage.getItem('token');
//   if (token !== null) {
//     headers.Authorization = `JWT ${token}`;
//   }
//   return fetch(`${process.env.API_URL}${endPoint}`, {
//     method: method.toUpperCase(),
//     headers: headers,
//   });
// };
