import { fetchApi } from "../../request";
export default {
  state: {
    subcode: [],
    totalSubCodeRow: 0,
    vendorList: null,
    subcodeList: null,
    subcodeListClient: null,
    careGiverList: null,
    UniqueEmployeesList: null,
    otherStaffList: null,
    AssocationParties: null,
  },
  getters: {
    subcode(state) {
      if (state.subcode.length > 0) {
        return state.subcode;
      }
    },
    totalSubCodeRow(state) {
      return state.totalSubCodeRow;
    },
    vendorList(state) {
      return state.vendorList;
    },
    subcodeList(state) {
      return state.subcodeList;
    },
    subcodeListClient(state) {
      return state.subcodeListClient;
    },
    careGiverList(state) {
      return state.careGiverList;
    },
    UniqueEmployeesList(state) {
      return state.UniqueEmployeesList;
    },
    otherStaffList(state) {
      return state.otherStaffList;
    },
    AssocationParties(state) {
      return state.AssocationParties;
    },
  },
  actions: {
    async getsubcode({ commit }, payload) {
      return await fetchApi("api/SubCode", payload)
        .then((response) => {
          commit("setsubcode", response.data);
          return response;
        })
        .catch((ex) => {});
    },
    savesubcode({ commit }, payload) {
      return fetchApi("api/SubCode", payload, "Post", {
        "Content-Type": "application/json",
      })
        .then((response) => {
          commit("savesubcode", payload);
          return response;
        })
        .catch((ex) => {});
    },
    editsubcode({ commit }, payload) {
      return fetchApi("api/SubCode", payload, "Put", {
        "Content-Type": "application/json",
      })
        .then((response) => {
          commit("updatesubcode", payload);
          return response;
        })
        .catch((ex) => {});
    },
    removesubcode({ commit }, payload) {
      return fetchApi(`api/SubCode/${payload.id}/`, null, "DELETE")
        .then((response) => {
          commit("removesubcode", payload.id);
          return response;
        })
        .catch((ex) => {});
    },
    removecareGiver({ commit }, payload) {
      return fetchApi(`api/Employee/${payload.id}/`, null, "DELETE")
        .then((response) => {
          return response;
        })
        .catch((ex) => {});
    },
    async getVendorList({ commit }) {
      return await fetchApi("api/Vendors/GetVendors")
        .then((response) => {
          commit("setVendorList", response.data);
          return response;
        })
        .catch((ex) => {});
    },
    async getVendorListbyPayerId({ commit }, payload) {
      return await fetchApi("api/Vendors/GetVendorsByPayerId", payload)
        .then((response) => {
          commit("setVendorList", response.data);
          return response;
        })
        .catch((ex) => {});
    },

    async getSubCodeList({ commit }) {
      return await fetchApi("api/SubCode/GetSubCode")
        .then((response) => {
          commit("setsubcodeList", response.data);
          return response;
        })
        .catch((ex) => {});
    },
    async getSubCodeListbyClientId({ commit }, payload) {
      return await fetchApi(
        `api/SubCode/GetSubCodeByClientId?id=${payload}`,
        null
      )
        .then((response) => {
          commit("setsubcodeListByClientId", response.data);
          return response;
        })
        .catch((ex) => {});
    },
    async getCareGiverList({ commit }) {
      return await fetchApi("api/Employee/GetEmployees")
        .then((response) => {
          commit(
            "setCareGiverList",
            response.data.sort((a, b) => (a.name > b.name ? 1 : -1))
          );
          return response;
        })
        .catch((ex) => {});
    },
    async GetUniqueEmployees({ commit }, payload) {
      return await fetchApi(
        `api/Employee/GetUniqueEmployees?id=${payload}`,
        null
      )
        .then((response) => {
          commit("setUniqueEmployeesList", response.data);
          return response;
        })
        .catch((ex) => {});
    },
    async getOtherStaffList({ commit }) {
      return await fetchApi("api/OtherStaff/GetOtherStaff")
        .then((response) => {
          commit("setOtherStaffList", response.data);
          return response;
        })
        .catch((ex) => {});
    },
    async getAssocationParties({ commit }, payload) {
      if (!payload) {
        return await fetchApi("api/Miscellaneous/GetAssocationParties")
          .then((response) => {
            commit("setAssocationParties", response.data);
            return response;
          })
          .catch((ex) => {});
      } else {
        return await fetchApi(
          `api/Miscellaneous/GetAssocationParties?id=${payload}`,
          null
        )
          .then((response) => {
            commit("setAssocationParties", response.data);
            return response;
          })
          .catch((ex) => {});
      }
    },
    async getSubCodeListByVendorId({ commit }, payload) {
      return await fetchApi("api/SubCode/GetSubCodeByVendorId", payload)
        .then((response) => {
          commit("setsubcodeList", response.data);
          return response;
        })
        .catch((ex) => {});
    },
  },
  mutations: {
    setsubcode(state, payload) {
      state.subcode = payload.output;
      state.totalVendorRow = payload.totalVendorRow;
    },
    setVendorList(state, payload) {
      state.vendorList = payload;
    },
    setsubcodeList(state, payload) {
      state.subcodeList = payload;
    },
    setsubcodeListByClientId(state, payload) {
      state.subcodeListClient = payload;
    },
    setCareGiverList(state, payload) {
      state.careGiverList = payload;
    },
    setUniqueEmployeesList(state, payload) {
      state.UniqueEmployeesList = payload;
    },
    setOtherStaffList(state, payload) {
      state.otherStaffList = payload;
    },
    setAssocationParties(state, payload) {
      state.AssocationParties = payload;
    },
    removesubcode(state, payload) {
      state.subcode = state.subcode.filter(function (value) {
        return value.id != payload;
      });
    },
    updatesubcode(state, payload) {
      var index = state.subcode.findIndex((x) => x.id == payload.id);
      state.subcode[index].type = payload.type;
      state.subcode[index].companyName = payload.companyName;
      state.subcode[index].phoneNumber = payload.phoneNumber;
      state.subcode[index].location = payload.location;
    },
    savesubcode(state, payload) {
      state.subcode.unshift(payload);
    },
  },
};
