import { fetchApi } from "../../request";
export default {
    state: {
        vendors: [],
        totalVendorRow: 0, 
    },
    getters: {
        vendors(state) {
            if (state.vendors.length > 0) {
                return state.vendors;
            }
        },
        totalVendorRow(state) {
            return state.totalVendorRow;
        }, 
    },
    actions: {
        async getVendors({ commit }, payload) {
            return await fetchApi("api/Vendors", payload)
                .then((response) => {
                
                    commit("setVendors", response.data);
                    return response.data;
                })
                .catch((ex) => {
                    
                });
        },
        saveVendors({ commit }, payload) {
            
            return fetchApi('api/Vendors', payload, 'Post', { "Content-Type": "application/json" })
                .then((response) => {
                    commit("saveVendors", {
                        id: response.data, type: payload.type,
                        companyName: payload.companyName,
                        phoneNumber: payload.phoneNumber,
                        location: payload.location,
                    }); return response;
                })
                .catch((ex) => {
                    
                });
        },
        editVendors({ commit }, payload) {
            return fetchApi('api/Vendors', payload, 'Put', { "Content-Type": "application/json" })
                .then((response) => { 
                    commit("updateVendors", payload);
                    return response
                })
                .catch((ex) => {
                    
                });
        },
        removeVendors({ commit }, payload) {
            
            return fetchApi(`api/Vendors/${payload.id}/`, null, 'DELETE')
                .then((response) => {

                    commit("removeVendors", payload.id); return response
                })
                .catch((ex) => {
                    ;
                    
                });
        },
        async getCaseWorkerList({ commit }) { 
            return await fetchApi("api/CaseWorker/GetCaseWorkers")
              .then((response) => { 
                commit("setCaseWorkerList", response.data);
                return response;
              })
              .catch((ex) => {
                
                
              });
          },
    },
    mutations: {
        setVendors(state, payload) {
            state.vendors = payload.output;
            state.totalVendorRow = payload.totalRow;
        },
        setCaseWorkerList(state, payload) {
            state.caseWorkerList = payload; 
        },
        removeVendors(state, payload) {
            state.vendors=state.vendors.filter(function (value) {
                return value.id != payload;
            });
        },
        updateVendors(state, payload) {
            
            var index = state.vendors.findIndex(x => x.id == payload.id);
            state.vendors[index].type = payload.type
            state.vendors[index].companyName = payload.companyName
            state.vendors[index].phoneNumber = payload.phoneNumber
            state.vendors[index].location = payload.location
        },
        saveVendors(state, payload) {
            
            state.vendors.unshift(payload);
        },
    },
};
