import { fetchApi } from "../../request";
export default {
  state: {
    clients: [],
    Email: [],
    EmailList: [],
    authorization: [],
    bulkAuthorization: [],
    payerList: [],
    clientList: [],
    siblingList: [],
    intake: [],
    Staffing: [],
    ClientContacts: [],
    ClientContactsPhone: [],
    AuthorizationReport: [],
    Tasks: [],
    Communications: [],
    authorize: [],
    employeeExpiration:[],
    totalRow: 0,
    totalIntakeRow: 0,
    totalStaffingRow: 0,
    totalEmailRow: 0,
    totalTasksRow: 0,
    totalCommunicationsRow: 0,
    totalAuthorizeRow: 0,
    totalBulkAuthorizeRow: 0,
    totalClientContactsRow: 0,
    totalClientContactsPhoneRow: 0,
    editClient: null,
    editIntake: null,
    editEmail: null,
    editauthorize: null,
    clientListTimeEntry: null,
    editStaffing: null,
    edittasks: null,
    editcommunications: null,
    editclientcontact: null,
    Evv: null,
  },
  getters: {
    
    GatEmployeeExpiration(state)
    {
      return state.employeeExpiration
    },
    clients(state) {
      return state.clients;
    },
    Email(state) {
      return state.Email;
    },
    authorization(state) {
      return state.authorize;
    },
    bulkAuthorization(state) {
      return state.bulkAuthorization;
    },
    intakes(state) {
      return state.intake;
    },
    staffing(state) {
      return state.Staffing;
    },
    clientContacts(state) {
      return state.ClientContacts;
    },
    getAuthorizationReport(state) {
      return state.AuthorizationReport;
    },
    getclientListTimeEntry(state) {
      return state.clientListTimeEntry;
    },
    clientContactsPhone(state) {
      return state.ClientContactsPhone;
    },
    Tasks(state) {
      return state.Tasks;
    },
    Communications(state) {
      return state.Communications;
    },
    getevv(state) {
      return state.Evv;
    },
    totalIntakeRow(state) {
      return state.totalIntakeRow;
    },
    totalStaffingRow(state) {
      return state.totalStaffingRow;
    },
    totalClientContactsRow(state) {
      return state.totalClientContactsRow;
    },
    totalClientContactsPhoneRow(state) {
      return state.totalClientContactsPhoneRow;
    },
    totalTasksRow(state) {
      return state.totalTasksRow;
    },
    totalCommunicationsRow(state) {
      return state.totalCommunicationsRow;
    },
    totalAuthorizeRow(state) {
      return state.totalAuthorizeRow;
    },
    totalBulkAuthorizeRow(state) {
      return state.totalBulkAuthorizeRow;
    },
    totalClientRow(state) {
      return state.totalRow;
    },
    totalEmailRow(state) {
      return state.totalEmailRow;
    },
    editClient(state) {
      return state.editClient;
    },
    editIntake(state) {
      return state.editIntake;
    },
    editauthorize(state) {
      return state.editauthorize;
    },
    editStaffing(state) {
      return state.editStaffing;
    },
    edittasks(state) {
      return state.edittasks;
    },
    editcommunications(state) {
      return state.editcommunications;
    },
    editclientcontact(state) {
      return state.editclientcontact;
    },
    PayersList(state) {
      return state.payerList;
    },
    ClientsList(state) {
      return state.clientList;
    },
    SiblingList(state) {
      return state.siblingList ? state.siblingList.output : null;
    },
  },
  actions: {
    async getEmail({ commit }, payload) {
      return await fetchApi(`api/EmailTemplate/getEmailTable`, payload)
        .then((response) => {
          commit("setEmail", response.data);
          return response;
        })
        .catch((ex) => {});
    },
    async getEmailList() {
      return await fetchApi(`api/EmailTemplate/GetEmailTemplate`)
        .then((response) => {
          return response;
        })
        .catch((ex) => {});
    },
    async getClients({ commit }, payload) {
      return await fetchApi("api/Client", payload)
        .then((response) => {
          commit("setClients", response.data);
          return response;
        })
        .catch((ex) => {});
    },
    async getClientsByEmployeeId({ commit }, payload) {
      return await fetchApi("api/Client/GetClientsByEmployeeId", payload)
        .then((response) => {
          commit("setClientsList", response.data);
          return response;
        })
        .catch((ex) => {});
    },
    async getEvv({ commit }, payload) {
      return await fetchApi(`api/ClientEVV/${payload}`, null)
        .then((response) => {
          commit("saveEvv", response.data);
          return response;
        })
        .catch((ex) => {});
    },
    async getClientInfo({ commit }, payload) {
      return await fetchApi(`api/Client/${payload}`, null)
        .then((response) => {
          commit("editClient", response.data);
          return response;
        })
        .catch((ex) => {});
    },
    async getIntakes({ commit }, payload) {
      return await fetchApi(`api/ClientInTakes/GetByClientId`, payload)
        .then((response) => {
          commit("setIntake", response.data);
          return response;
        })
        .catch((ex) => {});
    },
    async getStaffing({ commit }, payload) {
      return await fetchApi(`api/AssignCaregiver`, payload)
        .then((response) => {
          commit("setStaffing", response.data);
          return response;
        })
        .catch((ex) => {});
    },

    async getClientContacts({ commit }, payload) {
      return await fetchApi(`api/ClientContacts`, payload)
        .then((response) => {
          commit("setClientContacts", response.data);
          return response;
        })
        .catch((ex) => {});
    },
    async postSendEmail({ commit }, payload) {
      return fetchApi("api/Employee/SendEmail", payload, "Post", {
        "Content-Type": "application/json",
      })
        .then((response) => {
          if (response.message == "Success") {
            commit("postSendEmail", payload);
          }
          return response;
        })
        .catch((ex) => {});
    },
    async DuplicatingPreviousStaffing({ commit }, payload) {
      return await fetchApi(
        `api/AssignCaregiver/DuplicatingPreviousStaffing`,
        payload,
        "Post",
        {
          "Content-Type": "application/json",
        }
      )
        .then((response) => {
          return response;
        })
        .catch((ex) => {});
    },
    async updateBulkAuth({ commit }, payload) {
      return await fetchApi(
        `api/ClientAuthorization/UpdateBulkAuth`,
        payload,
        "Post",
        {
          "Content-Type": "application/json",
        }
      )
        .then((response) => {
          return response;
        })
        .catch((ex) => {});
    },
    removeClientContacts({ commit }, payload) {
      return fetchApi(`api/ClientContacts/${payload.id}/`, null, "DELETE")
        .then((response) => {
          return response;
        })
        .catch((ex) => {});
    },
    async getClientContactsPhone({ commit }, payload) {
      return await fetchApi(`api/ClientContacts/GetPhone`, payload)
        .then((response) => {
          commit("setClientContactsPhone", response.data);
          return response;
        })
        .catch((ex) => {});
    },
    async getTasks({ commit }, payload) {
      return await fetchApi(`api/Tasks`, payload)
        .then((response) => {
          commit("setTasks", response.data);
          return response;
        })
        .catch((ex) => {});
    },
    async getCommunications({ commit }, payload) {
      return await fetchApi(`api/ClientCommunication`, payload)
        .then((response) => {
          commit("setClientCommunications", response.data);
          return response;
        })
        .catch((ex) => {});
    },
    async CheckPreviousAuthorization({ commit }, payload) {
     
      return await fetchApi(
        `api/ClientAuthorization/CheckPreviousAuthorization?subcodeId=${payload.subcodeId}&clientId=${payload.clientId}`,
        null
      ).then((res) => { 
        return res;
      });
    },
    async getAuthorize({ commit }, payload) {
      return await fetchApi(`api/ClientAuthorization/GetByClientId`, payload)
        .then((response) => {
          commit("setAuthorize", response.data);
          return response;
        })
        .catch((ex) => {});
    },
    async GetSharedServicesData({ commit }, payload) {
      return await fetchApi(
        `api/ClientAuthorization/GetSharedServicesData`,
        null
      )
        .then((response) => {
          return response.data;
        })
        .catch((ex) => {});
    },
    async getBulkAuth({ commit }, payload) {
      return await fetchApi(
        `api/ClientAuthorization/GetAllAuthorization`,
        payload
      )
        .then((response) => {
          commit("setBulkAuthorize", response.data);
          return response;
        })
        .catch((ex) => {});
    },
    async getPayersList({ commit }) {
      return await fetchApi("api/Payers/GetPayers")
        .then((response) => {
          commit(
            "setPayerList",
            response.data.sort((a, b) =>
              a.companyName > b.companyName ? 1 : -1
            )
          );
          return response;
        })
        .catch((ex) => {});
    },
    async addEmail({ commit }, payload) {
      return fetchApi("api/EmailTemplate/addEmailTemplate", payload, "Post", {
        "Content-Type": "application/json",
      })
        .then((response) => {
          commit("addEmailList", response.data);
          return response;
        })
        .catch((ex) => {});
    },
    async getClientList({ commit }) {
      return await fetchApi("api/Client/GetClients")
        .then((response) => {
          commit("setClientList", response.data);
          return response;
        })
        .catch((ex) => {});
    },
    async getSiblings({ commit }, payload) {
      return await fetchApi("api/ClientsSiblings/GetByClientId", payload)
        .then((response) => {
          commit("setsiblingList", response.data);
          return response;
        })
        .catch((ex) => {});
    },
    saveClient({ commit }, payload) {
      return fetchApi("api/Client", payload, "Post", {
        "Content-Type": "application/json",
      })
        .then((response) => {
          if (response.message == "Success") {
            payload.id = response.data;
            commit("saveClients", payload);
          }
          return response;
        })
        .catch((ex) => {});
    },

    async saveAuthorization({ commit }, payload) {
      return await fetchApi("api/ClientAuthorization", payload, "Post", {
        "Content-Type": "application/json",
      })
        .then((response) => {
          if (response.message == "Success") {
            payload.id = response.data;
            commit("saveClientAuthorization", payload);
          }
          return response;
        })
        .catch((ex) => {});
    },
    updateAuthorization({ commit }, payload) {
      return fetchApi("api/ClientAuthorization", payload, "Put", {
        "Content-Type": "application/json",
      })
        .then((response) => {
          payload.id = response.data;
          commit("saveClientAuthorization", payload);
          return response;
        })
        .catch((ex) => {});
    },
    GetAuthorizationReport({ commit }, payload) {
      return fetchApi("api/ClientAuthorization/GetAuthorizationReport", null)
        .then((response) => {
          commit("saveAuthorizationReport", response.data);
          return response;
        })
        .catch((ex) => {});
    },
    addSiblings({ commit }, payload) {
      return fetchApi("api/ClientsSiblings", payload, "Post", {
        "Content-Type": "application/json",
      })
        .then((response) => {
          payload.id = response.data;
          commit("saveSiblings", payload);
          return response;
        })
        .catch((ex) => {});
    },
    async saveIntake({ commit }, payload) {
      return await fetchApi("api/ClientInTakes", payload, "Post", {
        "Content-Type": "application/json",
      })
        .then((response) => {
          commit("saveIntake", payload);
          return response;
        })
        .catch((ex) => {});
    },
    async updateIntake({ commit }, payload) {
      return await fetchApi("api/ClientInTakes", payload, "Put", {
        "Content-Type": "application/json",
      })
        .then((response) => {
          commit("saveIntake", payload);
          return response;
        })
        .catch((ex) => {});
    },
    async saveStaffing({ commit }, payload) {
      return await fetchApi("api/AssignCaregiver", payload, "Post", {
        "Content-Type": "application/json",
      })
        .then((response) => {
          commit("saveStaffing", payload);
          return response;
        })
        .catch((ex) => {});
    },
    async updateStaffing({ commit }, payload) {
      return await fetchApi("api/AssignCaregiver", payload, "Put", {
        "Content-Type": "application/json",
      })
        .then((response) => {
          commit("saveStaffing", payload);
          return response;
        })
        .catch((ex) => {});
    },
    async saveContacts({ commit }, payload) {
      return await fetchApi("api/ClientContacts", payload, "Post", {
        "Content-Type": "application/json",
      })
        .then((response) => {
          return response;
        })
        .catch((ex) => {});
    },
    async updateContacts({ commit }, payload) {
      return await fetchApi("api/ClientContacts", payload, "Put", {
        "Content-Type": "application/json",
      })
        .then((response) => {
          return response;
        })
        .catch((ex) => {});
    },
    saveContactsPhone({ commit }, payload) {
      return fetchApi("api/ClientContacts/PostPhone", payload, "Post", {
        "Content-Type": "application/json",
      })
        .then((response) => {
          return response;
        })
        .catch((ex) => {});
    },
    updateContactsPhone({ commit }, payload) {
      return fetchApi("api/ClientContacts/PutPhone", payload, "Put", {
        "Content-Type": "application/json",
      })
        .then((response) => {
          return response;
        })
        .catch((ex) => {});
    },

    async saveTasks({ commit }, payload) {
      return await fetchApi("api/Tasks", payload, "Post", {
        "Content-Type": "application/json",
      })
        .then((response) => {
          commit("saveTasks", payload);
          return response;
        })
        .catch((ex) => {});
    },
    async updateTasks({ commit }, payload) {
      return await fetchApi("api/Tasks", payload, "Put", {
        "Content-Type": "application/json",
      })
        .then((response) => {
          commit("saveTasks", payload);
          return response;
        })
        .catch((ex) => {});
    },
    async saveCommunication({ commit }, payload) {
      return await fetchApi("api/ClientCommunication", payload, "Post", {
        "Content-Type": "application/json",
      })
        .then((response) => {
          return response;
        })
        .catch((ex) => {});
    },
    async updateCommunication({ commit }, payload) {
      return await fetchApi("api/ClientCommunication", payload, "Put", {
        "Content-Type": "application/json",
      })
        .then((response) => {
          return response;
        })
        .catch((ex) => {});
    },
    async ImportClients({ commit }, payload) {
      return fetchApi("api/ImportClients", payload, "Post", {
        "Content-Type": "application/json",
      })
        .then((response) => {
          return response;
        })
        .catch((ex) => {});
    },
    async ImportAuthorizations({ commit }, payload) {
      return await fetchApi(
        "api/ClientAuthorization/ImportAuthorizations",
        payload,
        "Post",
        {
          "Content-Type": "application/json",
        }
      )
        .then((response) => {
          return response;
        })
        .catch((ex) => {});
    },
    saveEvv({ commit }, payload) {
      return fetchApi("api/ClientEVV", payload, "Post", {
        "Content-Type": "application/json",
      })
        .then((response) => {
          commit("saveEvv", payload);
          return response;
        })
        .catch((ex) => {});
    },
    updateClient({ commit }, payload) {
      return fetchApi("api/Client", payload, "Put", {
        "Content-Type": "application/json",
      })
        .then((response) => {
          commit("updateClients", payload);
          return response;
        })
        .catch((ex) => {});
    },
    updatePhoto({ commit }, payload) {
      return fetchApi(
        "​api​/Client​/UploadClientProfileImage",
        payload,
        "Post",
        { "Content-Type": "application/json" }
      )
        .then((response) => {
          return response;
        })
        .catch((ex) => {});
    },
    async editClient({ commit }, payload) {
      return await fetchApi(`api/Client/${payload}/`, null, "GET").then(
        (response) => {
          commit("editClient", response.data);
          return response.data;
        }
      );
    },
    async editEmail({ commit }, payload) {
      return await fetchApi(
        `api/EmailTemplate/EditEmailtemplate`,
        payload,
        "Post",
        { "Content-Type": "application/json" }
      ).then((response) => {
        commit("editEmail", response.data);
        return response.data;
      });
    },
    editIntake({ commit }, payload) {
      commit("editIntake", payload);
    },
    emptyEditClient({ commit }, payload) {
      commit("editClient", payload);
    },
    editauthorize({ commit }, payload) {
      commit("editauthorize", payload);
    },
    editStaffing({ commit }, payload) {
      commit("editStaffing", payload);
    },
    async edittasks({ commit }, payload) {
      if (payload) {
        return await fetchApi(`api/Tasks/${payload}/`, null, "GET").then(
          (response) => {
            commit("edittasks", response.data);
            return response.data;
          }
        );
      } else {
        commit("edittasks", payload);
      }
    },
    async editcommunications({ commit }, payload) {
      if (payload) {
        return await fetchApi(
          `api/ClientCommunication/${payload}/`,
          null,
          "GET"
        ).then((response) => {
          commit("editcommunications", response.data);
          return response.data;
        });
      } else {
        commit("editcommunications", payload);
      }
    },
    async editclientcontact({ commit }, payload) {
      if (payload) {
        return await fetchApi(
          `api/ClientContacts/${payload}/`,
          null,
          "GET"
        ).then((response) => {
          commit("editclientcontact", response.data);
          return response.data;
        });
      } else {
        commit("editclientcontact", payload);
      }
    },
    async removeTasks({ commit }, payload) {
      return await fetchApi(`api/Tasks/${payload.id}/`, null, "DELETE")
        .then((response) => {
          return response;
        })
        .catch((ex) => {});
    },
    async removeEmailTemplate({ commit }, payload) {
      return await fetchApi(
        `api/EmailTemplate/DeleteTemplate/${payload.id}/`,
        null,
        "DELETE"
      )
        .then((response) => {
          return response;
        })
        .catch((ex) => {});
    },
    async removeContactPhone({ commit }, payload) {
      return await fetchApi(
        `api/ClientContacts/DeletePhone?id=${payload.id}`,
        null,
        "DELETE"
      )
        .then((response) => {
          return response;
        })
        .catch((ex) => {});
    },
    async removeCommunications({ commit }, payload) {
      return await fetchApi(
        `api/ClientCommunication/${payload.id}/`,
        null,
        "DELETE"
      )
        .then((response) => {
          return response;
        })
        .catch((ex) => {});
    },
    removeClient({ commit }, payload) {
      return fetchApi(`api/Client/${payload.id}/`, null, "DELETE")
        .then((response) => {
          commit("removeClient", payload.id);
          return response;
        })
        .catch((ex) => {});
    },

    removeIntakeClient({ commit }, payload) {
      return fetchApi(`api/ClientInTakes/${payload.id}/`, null, "DELETE")
        .then((response) => {
          commit("removeClientInTakes", payload.id);
          return response;
        })
        .catch((ex) => {});
    },
    removeAuthClient({ commit }, payload) {
      return fetchApi(`api/ClientAuthorization/${payload.id}/`, null, "DELETE")
        .then((response) => {
          commit("removeClientAuthorization", payload.id);
          return response;
        })
        .catch((ex) => {});
    },
    async removeAssignCaregiver({ commit }, payload) {
      return await fetchApi(
        `api/AssignCaregiver/${payload.id}/`,
        null,
        "DELETE"
      )
        .then((response) => {
          return response;
        })
        .catch((ex) => {});
    },
    async getEmployeeExpirationReport({ commit }) {
      const apiUrl = "api/ClientAuthorization/GetEmployeeExpirationReport";
      return await fetchApi(apiUrl)
        .then((response) => {
          commit("setEmployeeExpirationReport", response.data);
          return response;
        })
        .catch((ex) => {
        });
    },
  },
  mutations: {
    setEmployeeExpirationReport (state, payload)
    {
      state.employeeExpiration = payload;
    },
    setEmail(state, payload) {
      state.Email = payload.output;
      state.totalEmailRow = payload.totalRow;
    },
    removeEmail(state, payload) {
      state.Email = state.Email.filter(function (value) {
        return value.id != payload;
      });
    },
    updateEmail(state, payload) {
      var index = state.Email.findIndex((x) => x.id == payload.id);
      state.Email[index] = payload;
    },
    saveEmail(state, payload) {
      state.Email.unshift(payload);
    },
    editEmail(state, payload) {
      state.editEmail = payload;
    },
    setClients(state, payload) {
      state.clients = payload.output;
      state.totalRow = payload.totalRow;
    },
    setClientsList(state, payload) {
      state.clientListTimeEntry = payload;
      state.clientList = payload;
      state.clientList.sort((a, b) => (a.name > b.name ? 1 : -1));
    },
    setIntake(state, payload) {
      state.intake = payload.output;
      state.totalIntakeRow = payload.totalRow;
    },
    setStaffing(state, payload) {
      state.Staffing = payload.output;
      state.totalStaffingRow = payload.totalRow;
    },
    setClientContacts(state, payload) {
      state.ClientContacts = payload.output;
      state.totalClientContactsRow = payload.totalRow;
    },
    setClientContactsPhone(state, payload) {
      state.ClientContactsPhone = payload.output;
      state.totalClientContactsPhoneRow = payload.totalRow;
    },
    setTasks(state, payload) {
      state.Tasks = payload.output;
      state.totalTasksRow = payload.totalRow;
    },
    setClientCommunications(state, payload) {
      state.Communications = payload.output;
      state.totalCommunicationsRow = payload.totalRow;
    },
    setAuthorize(state, payload) {
      state.authorize = payload.output;
      state.totalAuthorizeRow = payload.totalRow;
    },
    setBulkAuthorize(state, payload) {
      state.bulkAuthorization = payload.output;
      state.totalBulkAuthorizeRow = payload.totalRow;
    },
    setPayerList(state, payload) {
      state.payerList = payload;
    },
    setClientList(state, payload) {
      state.clientListTimeEntry = payload;
      state.clientList = payload;
      state.clientList.sort((a, b) => (a.name > b.name ? 1 : -1));
    },
    setsiblingList(state, payload) {
      state.siblingList = payload;
    },

    saveIntake(state, payload) {
      state.intake = payload;
    },
    saveStaffing(state, payload) {
      state.staffing = payload;
    },
    saveTasks(state, payload) {
      state.tasks = payload;
    },
    removeTasks(state, payload) {
      state.tasks = state.tasks.filter(function (value) {
        return value.id != payload;
      });
    },
    removeClient(state, payload) {
      state.clients = state.clients.filter(function (value) {
        return value.id != payload;
      });
    },
    removeClientInTakes(state, payload) {
      state.intake = state.intake.filter(function (value) {
        return value.id != payload;
      });
    },
    removeClientAuthorization(state, payload) {
      state.authorize = state.authorize.filter(function (value) {
        return value.id != payload;
      });
    },
    removeAssignCaregiver(state, payload) {
      state.staffing = state.staffing.filter(function (value) {
        return value.id != payload;
      });
    },
    updateClients(state, payload) {
      var index = state.clients.findIndex((x) => x.id == payload.id);
      state.clients[index] = payload;
    },
    saveClients(state, payload) {
      state.clients.unshift(payload);
    },
    saveSiblings(state, payload) {
      state.siblingList.unshift(payload);
    },
    ClientAuthorization(state, payload) {
      state.authorization.unshift(payload);
    },
    editClient(state, payload) {
      state.editClient = payload;
    },
    editIntake(state, payload) {
      state.editIntake = payload;
    },
    editauthorize(state, payload) {
      state.editauthorize = payload;
    },
    editStaffing(state, payload) {
      state.editStaffing = payload;
    },
    edittasks(state, payload) {
      state.edittasks = payload;
    },
    editcommunications(state, payload) {
      state.editcommunications = payload;
    },
    editclientcontact(state, payload) {
      state.editclientcontact = payload;
    },
    saveEvv(state, payload) {
      state.Evv = payload;
    },
    saveAuthorizationReport(state, payload) {
      state.AuthorizationReport = payload;
    },
  },
};
