import { fetchApi } from "../../request";
export default {
  state: {
    BillingReport: [],
    BillingReportNew: {},
    OvertimeReport: [],
    ClientsReport: [],
    EmployeeReport: [],
    clientRatio: null,
    refferal: [],
    refferaldetail: [],
    Census: [],
    InactivityReport: [],
    JFSReport: [],
    employeeCount: 0,
    RegionalCenterInvoicing:[],
    VendorCodeByPayerId:{},
  },
  getters: {
    BillingReport(state) {
      return state.BillingReport;
    },
    BillingReportNew(state) {
      return state.BillingReportNew;
    },
    ClientsReport(state) {
      return state.ClientsReport;
    },
    EmployeeReport(state) {
      return state.EmployeeReport;
    },
    Getrefferal(state) {
      return state.refferal;
    },
    GetrefferalDetail(state) {
      return state.refferaldetail;
    },
    getCensus(state) {
      return state.Census;
    },
    employeeCount(state) {
      return state.employeeCount;
    },
    clientRatio(state) {
      return state.clientRatio;
    },
    OvertimeReport(state) {
      return state.OvertimeReport;
    },
    InactivityReport(state) {
      return state.InactivityReport;
    },
    JFSReport(state) {
      return state.JFSReport;
    },
    GetRegionalCenterInvoicings(state)
    {
      return state.RegionalCenterInvoicing;
    },
    GetVendorCodeByPayerIds(state)
    {
      return state.VendorCodeByPayerId;
    }
  },
  actions: {
    async getBillingReports({ commit }, payload) {
      return await fetchApi("api/Report", payload)
        .then((response) => {
          commit("setTimePeriod", response.data.output);
          return response.data.output;
        })
        .catch((ex) => {});
    },
    async getNewBillingReports({ commit }, payload) {
      return await fetchApi("api/Report/GetBillingReport", payload)
        .then((response) => { 
          commit("setNewTimePeriod", response);
          return response;
        })
        .catch((ex) => {});
    },
    async getOvertimeReports({ commit }, payload) {
      return await fetchApi("api/Report/GetOvertimeReport", payload)
        .then((response) => {
          commit("setOvertimeReport", response.data);
          return response.data;
        })
        .catch((ex) => {});
    },
    async getInactivityReports({ commit }, payload) {
      return await fetchApi("api/Report/GetInactivityReports", payload)
        .then((response) => {
          commit("setInactivityReport", response.data);
          return response.data;
        })
        .catch((ex) => {});
    },
    async GetJFSUserReport({ commit }, payload) {
      return await fetchApi("api/Report/GetJFSUserReport", payload)
        .then((response) => {
          commit("setJFSReport", response.data);
          return response.data;
        })
        .catch((ex) => {});
    },
    
    async GetRegionalCenterInvoicing({ commit }, payload) {
      return await fetchApi("api/Report/GetRegionalCenterInvoicing", payload)
        .then((response) => {
          commit("setRegionalCenterInvoicing", response.data);
          return response.data;
        })
        .catch((ex) => {});
    },
    async getClientReports({ commit }, payload) {
      return await fetchApi("api/Report/GetClientsReport", payload)
        .then((response) => {
          commit("setClientsReport", response.data.output);
          return response.data.output;
        })
        .catch((ex) => {});
    },
    async getEmployeeReport({ commit }, payload) {
      return await fetchApi("api/Report/GetEmployeesReport", payload)
        .then((response) => {
          commit("setEmployeeReport", response.data.output);
          return response.data.output;
        })
        .catch((ex) => {});
    },
    async getCensusSummary({ commit }) {  
      await fetchApi("api/Employee/GeActiveEmployee").then((response) => {
        commit("setEmployeeCount", response.data.totalEmployees);
      });
      await fetchApi("api/Report/GetClientratio").then((response) => {
        commit("setClientRatio", response.data);
      });
      return await fetchApi("api/Report/GetCensus")
        .then((response) => {
          commit("setCensus", response.data.output);
          return response.data.output;
        })
        .catch((ex) => {});
    },
    async getRefferalSummary({ commit }, payload) {
      return await fetchApi("api/Report/GetReferal", payload).then(
        (response) => {
          commit("setReferal", response.data);
          return response.data;
        }
      );
    },
    async getRefferalDetailSummary({ commit }, payload) {
      return await await fetchApi("api/Report/GetReferalDetail", payload).then(
        (response) => {
          commit("setReferalDetail", response.data);
          return response.data;
        }
      );
    }, 
    async GetVendorCodeByPayerId({ commit }, payerId) {
      try {
        const apiUrl = `api/Report/GetVendorCodeByPayerId/${payerId}`;
        const response = await fetchApi(apiUrl);
        const vendorData = response.data.data;
        commit("setVendorCodeByPayerId", vendorData);
        return vendorData;
      } catch (error) {
        console.error('Error fetching vendor list:', error);
        throw error;
      }
    } 
    
  },
  mutations: {
    setTimePeriod(state, payload) {
      state.BillingReport = payload;
    },
    setVendorCodeByPayerId (state, payload)
    {
      state.VendorCodeByPayerId = payload;
    },
    setNewTimePeriod(state, payload) {
      state.BillingReportNew = payload;
    },
    setCensus(state, payload) {
      state.Census = payload;
    },
    setEmployeeCount(state, payload) {
      state.employeeCount = payload;
    },
    setClientRatio(state, payload) {
      state.clientRatio = payload;
    },
    setReferal(state, payload) {
      state.refferal = payload;
    },
    setReferalDetail(state, payload) {
      state.refferaldetail = payload;
    },
    setClientsReport(state, payload) {
      state.ClientsReport = payload;
    },
    setEmployeeReport(state, payload) {
      state.EmployeeReport = payload;
    },
    setOvertimeReport(state, payload) {
      state.OvertimeReport = payload;
    },
    setInactivityReport(state, payload) {
      state.InactivityReport = payload;
    },
    setJFSReport(state, payload) {
      state.JFSReport = payload;
    },
    setRegionalCenterInvoicing( state, payload)
    {
      state.RegionalCenterInvoicing = payload;
    }
  },
};
