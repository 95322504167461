const ID_TOKEN_KEY = "ZX52998VX";
const CryptoJS = require("crypto-js");

export const getToken = () => {
  if (localStorage.getItem(ID_TOKEN_KEY) == null) {
    return null;
  }
  return CryptoJS.AES.decrypt(
    localStorage.getItem(ID_TOKEN_KEY),
    "PDv7DrqznYL6nv7DrqzjnQYO9JxIsWdcjnQYL6nu0f"
  ).toString(CryptoJS.enc.Utf8);
};

export const saveToken = (token) => {
  window.localStorage.setItem(
    ID_TOKEN_KEY,
    CryptoJS.AES.encrypt(
      token,
      "PDv7DrqznYL6nv7DrqzjnQYO9JxIsWdcjnQYL6nu0f"
    ).toString()
  );
};

export const destroyToken = () => {
  window.localStorage.removeItem(ID_TOKEN_KEY);
};

export default { getToken, saveToken, destroyToken };
