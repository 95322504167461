import { fetchApi } from "../../request";
import store from "@/core/services/store";
export default {
  state: {
    TimePeriod: [],
    Batch: [],
  },
  getters: {
    TimePeriod(state) {
      if (state.TimePeriod) {
        return state.TimePeriod.data;
      }
    },
    ClientCount(state) {
      if (state.TimePeriod) {
        if (store.getters.GetAuthenticatedUser.userType == "JFS") {
          return state.TimePeriod.clients.filter((x) => x.companyName == "JFS")
            .length;
        } else if (
          store.getters.GetAuthenticatedUser.userType == "Home Health"
        ) {
          return state.TimePeriod.clients.filter(
            (x) => x.companyName == "Home Health"
          ).length;
        } else {
          return state.TimePeriod.clients.length;
        }
      }
    },
    Batch(state) {
      return state.Batch;
    },
  },
  actions: {
    async addBatch({ commit }, payload) {
      return await fetchApi(
        "api/PayRoll",
        {
          batchName: payload.batchName,
          payDate: payload.payDate,
          startDate: payload.startDate,
          endDate: payload.endDate,
        },
        "Post",
        {
          "Content-Type": "application/json",
        }
      )
        .then((response) => {
          commit("addBatch", payload);
          return response;
        })
        .catch((ex) => {});
    },
    async getTimePeriod({ commit }, payload) {
      return await fetchApi("api/PayRoll", payload)
        .then((response) => {
          commit("setTimePeriod", response.data);
          return response;
        })
        .catch((ex) => {});
    },
    resetTimePeriod({ commit }, payload) {
      commit("resetTimePeriod");
    },
    removepayRoll({ commit }, payload) {
      return fetchApi(`api/PayRoll/${payload}/`, null, "DELETE")
        .then((response) => {
          commit("removesubcode", payload);
          return response;
        })
        .catch((ex) => {});
    },
    async GetBatchName({ commit }) {
      return await fetchApi("api/PayRoll/GetBatchName")
        .then((response) => {
          commit("setBatch", response.data);
          return response.date;
        })
        .catch((ex) => {});
    },
    async getBatchData({ commit }, payload) {
      return await fetchApi("api/PayRoll/GetBatchNameById", payload)
        .then((response) => {
          commit("setTimePeriod", response.data);
          return response.date;
        })
        .catch((ex) => {});
    },
  },
  mutations: {
    setTimePeriod(state, payload) {
      state.TimePeriod = payload;
    },
    resetTimePeriod(state, payload) {
      state.TimePeriod = [];
    },
    setBatch(state, payload) {
      state.Batch = payload;
    },
    addBatch(state, payload) {
      state.Batch.unshift(payload);
    },
  },
};
